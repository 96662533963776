body {
	
	--swoop-color: #777;

	&.blue-swoop {
		--swoop-color: var(--eforall-blue);
	}

	&.green-swoop {
		--swoop-color: var(--eforall-green);
	}

	&.navy-swoop {
		--swoop-color: var(--eforall-navy);
	}
}

.header-swoop,
.footer-swoop {

	.bg-highlight {
		background-color: var(--swoop-color);
		color: #FFF !important;
	}
}