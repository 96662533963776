@use '../mobile/mobile-variables.scss' as MOBILE;


$card-over-bg-zindex: 2;
$card-under-bg-zindex: 1;


.card {
	-webkit-transform: translate3d(0, 0, 0);
	background-size: cover;
	border: 0px !important;
	max-height: 150000px;
	margin-bottom: MOBILE.$globalMargin;
	background-position: center center !important;
}

.card-style {
	overflow: hidden;
	margin: 0px 15px MOBILE.$globalMargin 15px;
	border-radius: MOBILE.$rounded-card-style;
	border: none;
	box-shadow: MOBILE.$globalShadow;

	.card-body {
		padding: 15px;
	}
}


.card-style .card-overlay {
	border-radius: MOBILE.$rounded-card-style;
}


.card-body {
	z-index: $card-over-bg-zindex;
}


.card-overlay {
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	position: absolute;
	z-index: $card-under-bg-zindex;
}


.card-overlay {
	background-position: center center;
	background-size: cover !important;
}

.opacity-95 {
	opacity: 0.95 !important;
}

.card-bg {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	background-size: cover;
	z-index: 0;
}
