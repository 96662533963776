@use '../common/common-variables.scss' as COMMON;


/****** Reds ******/
.fa-duotone {
	--fa-primary-color: rgb(0, 163, 224);
	--fa-secondary-color: rgb(0, 163, 224);
	--fa-secondary-opacity: .5;
}

.color-white {
	color: white;

	.fa-regular,
	.fa-solid,
	.fa-duotone {
		color: white;
	}

	.fa-duotone,
	&.fa-duotone {
		--fa-primary-color: white;
		--fa-secondary-color: white;
	}
}

.color-red {
	color: red;

	.fa-regular,
	.fa-solid,
	.fa-duotone {
		color: red;
	}

	.fa-duotone,
	&.fa-duotone {
		--fa-primary-color: red;
		--fa-secondary-color: red;
	}
}

.color-darkred {
	color: darkred !important;

	.fa-regular,
	.fa-solid,
	.fa-duotone {
		color: darkred;
	}

	.fa-duotone,
	&.fa-duotone {
		--fa-primary-color: darkred;
		--fa-secondary-color: darkred;
	}
}

.color-violet {
	color: violet;

	.fa-regular,
	.fa-solid,
	.fa-duotone {
		color: violet;
	}

	.fa-duotone,
	&.fa-duotone {
		--fa-primary-color: violet;
		--fa-secondary-color: violet;
	}
}

.color-mediumpurple {
	color: mediumpurple;

	.fa-regular,
	.fa-solid,
	.fa-duotone {
		color: mediumpurple;
	}

	.fa-duotone,
	&.fa-duotone {
		--fa-primary-color: mediumpurple;
		--fa-secondary-color: mediumpurple;
	}
}


/****** Oranges ******/

.color-darkorange {
	color: darkorange;

	.fa-regular,
	.fa-solid,
	.fa-duotone {
		color: darkorange;
	}

	.fa-duotone,
	&.fa-duotone {
		--fa-primary-color: darkorange;
		--fa-secondary-color: darkorange;
	}
}


/****** Grays ******/

.color-lightgray {
	color: #DDD
}

.color-gray {
	color: gray
}

.color-darkgray {
	color: #666;
}


/****** Greens ******/

.color-green {
	color: green;

	.fa-regular,
	.fa-solid,
	.fa-duotone {
		color: green;
	}

	.fa-duotone,
	&.fa-duotone {
		--fa-primary-color: green;
		--fa-secondary-color: green;
	}
}

.color-darkgreen {
	color: darkgreen;

	.fa-regular,
	.fa-solid,
	.fa-duotone {
		color: darkgreen;
	}

	.fa-duotone,
	&.fa-duotone {
		--fa-primary-color: darkgreen;
		--fa-secondary-color: darkgreen;
	}
}


/****** Gold ******/
.color-gold {
	color: goldenrod;

	.fa-regular,
	.fa-solid,
	.fa-duotone {
		color: goldenrod;
	}

	.fa-duotone,
	&.fa-duotone {
		--fa-primary-color: goldenrod;
		--fa-secondary-color: goldenrod;
	}
}

/****** Named ******/

.color-icon-slash {
	color: Tomato;
}

.color-redflag {
	color: #911
}

/****** EforAll Colors ******/
.color-eforall-navy {
	color: COMMON.$eforall-navy;

	.fa-regular,
	.fa-solid,
	.fa-duotone {
		color: COMMON.$eforall-navy;
	}

	.fa-duotone,
	&.fa-duotone {
		--fa-primary-color: COMMON.$eforall-navy;
		--fa-secondary-color: COMMON.$eforall-navy;
	}
}

.color-eforall-light-green {
	color: COMMON.$eforall-light-green;

	.fa-regular,
	.fa-solid,
	.fa-duotone {
		color: COMMON.$eforall-light-green;
	}

	.fa-duotone,
	&.fa-duotone {
		--fa-primary-color: COMMON.$eforall-light-green;
		--fa-secondary-color: COMMON.$eforall-light-green;
	}
}

.color-eforall-blue {
	color: COMMON.$eforall-blue;

	.fa-regular,
	.fa-solid,
	.fa-duotone {
		color: COMMON.$eforall-blue;
	}

	.fa-duotone,
	&.fa-duotone {
		--fa-primary-color: COMMON.$eforall-blue;
		--fa-secondary-color: COMMON.$eforall-blue;
	}
}

.color-eforall-orange {
	color: COMMON.$eforall-orange;

	.fa-regular,
	.fa-solid,
	.fa-duotone {
		color: COMMON.$eforall-orange;
	}

	.fa-duotone,
	&.fa-duotone {
		--fa-primary-color: COMMON.$eforall-orange;
		--fa-secondary-color: COMMON.$eforall-orange;
	}
}


/** Rating color **/
.color-rating-1 {
	color: COMMON.$rating-1;
}
.color-rating-2 {
	color: COMMON.$rating-2;
}
.color-rating-3 {
	color: COMMON.$rating-3;
}
.color-rating-4 {
	color: COMMON.$rating-4;
}
.color-rating-5 {
	color: COMMON.$rating-5;
}