@use 'sass:color';
@use "../common/common-variables.scss" as COMMON;

// $custom-colors: (
// 	"primary": COMMON.$eforall-light-green,
// 	// "standard": $light,
// 	"eforall-blue": COMMON.$eforall-blue,
// 	"eforall-green": COMMON.$eforall-green,
// 	"eforall-orange": COMMON.$eforall-orange,
// );


// $theme-colors: map-merge($theme-colors, $custom-colors);

$primary: COMMON.$eforall-light-green;

// set changes
@use "bootstrap/scss/bootstrap";

.btn-primary,
.btn-secondary {
	color: #000 !important;
}

.btn-primary {
	background-color: COMMON.$eforall-light-green !important;
}

.btn:not(.btn-flat) {
	border: solid 1px rgba(0, 0, 0, 0.2);
}

.btn:not(.btn-flat):not([disabled]) {

	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
	position: relative;

	&:active {
		border: solid 1px rgba(0, 0, 0, 0.3);

		&:hover {
			box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
			top: 1px;
			left: 1px;
		}
	}

	&.btn-standard:hover {
		background-color: color.scale(COMMON.$eforall-blue, $lightness: 40%);
	}
}

.btn:not(.btn-flat)[disabled] {
	background-color: transparent;
	color: rgba(0, 0, 0, 0.7) !important;
}

.btn-large {
	min-width: 15em;
}




.offcanvas {
	transition: none !important;
}


html {
	min-height: 100%;
}


body {
	font-size: 14px;
}