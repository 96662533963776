@use "../common/common-variables" as COMMON;
@use "../mobile/mobile-variables" as MOBILE;


@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Extra+Condensed:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Fira+Mono:400,700');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;500&display=swap');

$font-family: 'Fira Sans', sans-serif;
$condensed-font-family: "Fira Sans Condensed", sans-serif;
$extra-condensed-font-family: "Fira Sans Extra Condensed", sans-serif;
$monospace-font-family: "Fira Mono", monospace;
$agreement-text-font-family: "Noto Serif";


$width-large-font-family: $font-family;
$width-medium-font-family: $condensed-font-family;
$width-small-font-family: $extra-condensed-font-family;

* {
	letter-spacing: unset !important;
}

.btn {
	font-size: 14px;
}

@media (min-width: MOBILE.$breakpoint-tablet) {
	body {
		font-family: $width-small-font-family;
	}
}

@media (min-width: MOBILE.$breakpoint-tablet) and (max-width: MOBILE.$breakpoint-computer) {
	body {
		font-family: $width-medium-font-family;
	}
}

@media (min-width: MOBILE.$breakpoint-computer) {
	body {
		font-family: $width-large-font-family;
	}
}