.mobile-list-container {
	background-color: white;
	border-radius: 7px;
	border: 1px solid #ddd;
	user-select: none;
	overflow: hidden;

	a {
		cursor: pointer;
		text-decoration: none;
		padding: 0.5em 0.75em;
		color: #1f1f1f;
		height: 54px;


		&:not(:last-child) {
			border-bottom: 1px solid #ddd;
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.03);
		}

	}
}