h4,
h5,
h6,
.h4,
.h5,
.h6 {
	font-size: 1rem;
}


h3,
.h3 {
	font-size: 1.25rem;
}

h2,
.h2,
h1,
.h1 {
	font-size: 1.5rem;
}