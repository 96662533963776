.btn-bubble {

	background-color: #FFF;
	color: black;
	cursor: pointer;
	text-decoration: none;
	border: 1px solid #BBB;
	border-radius: 10px;
	padding: 0.4em 0.1em;
	box-shadow: inset 0 -0.1em 0.3em rgba(0, 0, 0, 0.3);
	user-select: none;

	&:active:hover {
		box-shadow: inset 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
		> * {
			position: relative;
			top: 1px;
			left: 1px;
		}
	}
}