@use "mobile-variables";
@use "mobile-bootstrap-override";
@use "../common/root.scss";
@use "@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@use "@fortawesome/fontawesome-pro/scss/regular.scss";
@use "@fortawesome/fontawesome-pro/scss/solid.scss";
@use "@fortawesome/fontawesome-pro/scss/brands.scss";
@use "@fortawesome/fontawesome-pro/scss/duotone.scss";
// @use "@fortawesome/fontawesome-pro/scss/_duotone-icons.scss";
@use "mobile-swoops";
@use "mobile-scrollbars";
@use "mobile-text-classes";
@use "mobile-color-classes";
@use "mobile-fonts";
@use "mobile-headers";
@use "mobile-buttons";
@use "mobile-cards";
@use "mobile-lists";


body {
	background: #eef3f3;
	overflow: hidden;
}