
/**** Breakpoints (Constants in layout.service must match these) ****/
$breakpoint-tablet: 480px;
$breakpoint-computer: 925px;
$breakpoint-wide-computer: 1350px;


$rounded-card-style: 20px;
$color-dark: #1f1f1f;
$color-white: #FFF;
$color-black: #000;
$color-transparent: rgba(0, 0, 0, 0);
$globalMargin: 30px;
$globalShadow: 0 4px 24px 0 rgba(0, 0, 0, .1);

//Transitions for Menus, Action Sheets, Modals etc*/
$disabledTransitions: all 0ms ease;
$globalTransitions: all 300ms ease;
